import { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import StoryTelling from "../components/SalesPageComponents/StoryTelling";
import CalendlyWidget from "../components/UpSell2/CalendlyWidget";
import CallToAction from "../components/UpSell2/CallToAction";
import CloseWarning from "../components/UpSell2/CloseWarning";
import MainStory from "../components/UpSell2/MainStory";
import StorytellingParagraph from "../components/ui/StoryTelling/StoryTellingParagraph";
import { ROUTES } from "../constants";
import { useDelayNavigation } from "../hooks/useDelayNavigation";
import { cn } from "../utils/utils";

const kUpsell2MagicKey = "d29yZA";
const kUpsell2MagicParam = "8df22268";

const UpSell2Page = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useDelayNavigation();

  const magicParam = params.get(kUpsell2MagicKey) ?? "";
  const videoParam = params.get("video") === "2";

  const wistiaId =
    magicParam == kUpsell2MagicParam || videoParam
      ? "5sbf9r9zav"
      : "hf1a8s69w5";

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";

    return () => {
      document.documentElement.style.scrollBehavior = "";
    };
  }, []);

  const handleNavigateToMemberShipPage = () => {
    navigate(ROUTES.MEMBERSHIP, {
      state: { from: `${location.pathname}?${location.search}` },
    });
  };

  return (
    <>
      <CloseWarning />
      <CallToAction wistiaId={wistiaId} />

      <MainStory>
        <div
          id="calendar"
          className={cn(
            "mx-auto",
            "[&_.MuiDateCalendar-root]:!h-[auto] [&_.MuiDateCalendar-root]:!max-h-[340px]",
            "md:[&_.MuiDateCalendar-root]:!h-full md:[&_.MuiDateCalendar-root]:!max-h-[500px]",
            "[&_.MuiDateCalendar-root]:w-[calc(100%-48px)] md:[&_.MuiDateCalendar-root]:w-[502px]",
          )}
        >
          <CalendlyWidget />
        </div>

        {/* <Center>
          <span
            className="mb-12.5 cursor-pointer px-6 text-center text-18 font-bold text-primary underline underline-offset-[5px] md:mb-15 md:px-0 md:text-24 md:uppercase mt-[30px] md:mt-10"
            onClick={handleNavigateToMemberShipPage}
          >
            No, thanks I don't want any help with <br className="md:hidden" />{" "}
            choosing my channel idea
          </span>
        </Center> */}

        <StoryTelling>
          <StorytellingParagraph
            title={`Why Is the "1:1 Channel Introductory Session" Free? What's The Catch?`}
            titleProps={{ className: "md:max-w-[782px]" }}
          >
            <p>
              We have done the channel introductory session with many of our
              members and it helped countless individuals get from "too many
              ideas and I don't know which one to pursue" or "no ideas at all"
              to finding a <br /> clear profitable faceless channel idea.
            </p>
            <p>
              I've been personally so impressed with{" "}
              <br className="sm:hidden" /> many of the channel ideas, that I
              have <br className="sm:hidden" /> decided to continue working with
              many of these members to this day.
            </p>
            <p>
              So to put my best foot forward this session{" "}
              <br className="sm:hidden" /> is included for free.
            </p>
            <p>
              The only catch is that we only have a <br className="sm:hidden" />{" "}
              single team member doing these sessions,{" "}
              <br className="sm:hidden" /> so the spots fill up really quick, So{" "}
              <Link
                to="#calendar"
                className="font-medium text-primary underline underline-offset-[5px]"
              >
                scroll <br className="sm:hidden" /> up
              </Link>{" "}
              and select a time that works for you.
            </p>
            <p>
              If you can't find any spots on the calendar,{" "}
              <br className="sm:hidden" /> that means we're completely booked
              out.
            </p>
          </StorytellingParagraph>

          <StorytellingParagraph
            title={
              <>
                What is the{" "}
                <span className="italic">
                  1:1 Channel Introductory Session?
                </span>{" "}
              </>
            }
            titleProps={{ className: "md:max-w-[513px]" }}
          >
            <p>
              The "Channel Introductory Session" is a{" "}
              <br className="sm:hidden" /> quick Zoom call with a professional{" "}
              <br className="sm:hidden" /> YouTube coach from my team.
            </p>

            <p>
              During this session, we'll nail down the{" "}
              <br className="sm:hidden" /> ideal channel concept that’s
              customized <br className="sm:hidden" /> just for you.
            </p>

            <p>
              We're offering this session free of charge{" "}
              <br className="sm:hidden" /> as a welcome gift to ensure you start
              off <br className="sm:hidden" /> on the right foot—because
              choosing the perfect channel idea is crucial to your{" "}
              <br className="sm:hidden" /> entire YouTube journey! 👍
            </p>
          </StorytellingParagraph>
        </StoryTelling>
      </MainStory>

      {/* Calendar script */}
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
      ></script>
    </>
  );
};

export default UpSell2Page;
